<template>
  <slot name="activator" v-bind="{ openModal }"/>

  <focus-trap v-if="showModal" v-model:active="showModal">
    <div
      class="bg-zinc-950/75 fixed inset-0 grid wh-screen md:p-4 lg:p-8 xl:p-16 items-center justify-center text-white overflow-auto select-none z-[2000]"
      role="dialog"
      @click="closeModal"
    >
      <div @click.stop>
        <slot/>
      </div>

      <button
        class="absolute top-0 right-0 px-1 py-2 lg:px-3 lg:py-4 ring-inset ring-white text-2xl lg:text-4xl text-white"
        aria-label="Close modal"
        type="button"
        @click="closeModal"
      >
        <icon name="close"/>
      </button>
    </div>
  </focus-trap>
</template>

<script setup>
import Icon from "@/components/Icon.vue"
import { FocusTrap } from "focus-trap-vue"
import { ref, watch } from "vue"

const props = defineProps({
  open: Boolean,
})

const emit = defineEmits(["open", "close"])

const showModal = ref(false)

const openModal = () => {
  showModal.value = true
  emit("open")
}

const closeModal = () => {
  showModal.value = false
  emit("close")
}

watch(() => props.open, (value) => {
  showModal.value = value
})
</script>
