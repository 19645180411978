<template>
  <div :class="{ 'lg:flex-wrap': selectedFilters }">
    <button
      class="
        flex bg-white border border-solid border-slate-300 hover:bg-slate-100 m-px p-2
        font-semibold items-center gap-1 shrink-0 text-inherit rounded-full
      "
      type="button"
      @click="toggleFilters"
    >
      <i class="fa fa-fw" :class="{ 'fa-sliders': !showFilters, 'fa-times': showFilters }"/>
      {{ !showFilters ? 'Filters' : 'Close filters' }}
    </button>

    <template v-if="selectedFilters">
      <button
        v-if="query.length"
        class="
          flex bg-white border border-solid border-slate-300 hover:bg-slate-100 m-px p-2
          font-semibold items-center gap-1 shrink-0 text-inherit rounded-full
        "
        type="button"
        @click="resetQuery"
      >
        <img v-if="place && place.image" :src="place.image" class="w-5 h-5 object-cover rounded-full" alt="">
        <span v-else aria-hidden="true" class="w-5 h-5 text-center" role="presentation">🔍</span>
        {{ place && place.name || query }}
        <i class="fa fa-times fa-fw"/>
      </button>

      <button
        v-for="selectedFilter in filters"
        :key="selectedFilter.name"
        class="
          flex bg-white border border-solid border-slate-300 hover:bg-slate-100 m-px p-2
          font-semibold items-center gap-1 shrink-0 text-inherit rounded-full
        "
        type="button"
        @click="removeFilter(selectedFilter)"
      >
        <span class="flex gap-1" v-html="selectedFilter.labels[0].innerHTML"/>
        <i class="fa fa-times fa-fw"/>
      </button>

      <button
        v-if="date"
        class="
          flex bg-white border border-solid border-slate-300 hover:bg-slate-100 m-px p-2
          font-semibold items-center gap-1 shrink-0 text-inherit rounded-full
        "
        type="button"
        @click="resetDate"
      >
        {{ date }}
        <i class="fa fa-times fa-fw"/>
      </button>

      <button
        v-if="duration"
        class="
          flex bg-white border border-solid border-slate-300 hover:bg-slate-100 m-px p-2
          font-semibold items-center gap-1 shrink-0 text-inherit rounded-full
        "
        type="button"
        @click="resetDuration"
      >
        <span v-html="duration"/>
        <i class="fa fa-times fa-fw"/>
      </button>

      <button
        v-if="price"
        class="
          flex bg-white border border-solid border-slate-300 hover:bg-slate-100 m-px p-2
          font-semibold items-center gap-1 shrink-0 text-inherit rounded-full
        "
        type="button"
        @click="resetPrice"
      >
        <span v-html="price"/>
        <i class="fa fa-times fa-fw"/>
      </button>

      <button
        class="
          flex group border border-solid border-red hover:bg-red hover:text-white m-px p-2
          font-semibold items-center gap-1 shrink-0 text-red rounded-full
        "
        type="reset"
      >
        <span aria-hidden="true" class="group-hover:brightness-[1000]" role="presentation">❌</span>
        Clear filters
      </button>

      <button
        v-for="suggestion in suggestions"
        :key="suggestion.name"
        class="
          flex bg-white border-2 border-dashed border-slate-200 p-2 font-semibold items-center gap-1 opacity-75 shrink-0 text-inherit rounded-full
          hover:opacity-100 hover:text-inherit hover:no-underline
        "
        @click.prevent="addFilter(suggestion)"
        v-html="suggestion.labels[0].innerHTML"
      />
    </template>

    <slot v-else v-bind="{ addFilter, showFilters, toggleFilters }"/>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue"
import { filter, find, flatten, groupBy, map, uniq, zip } from "lodash-es"
import { formatDate } from "@/dates"
import { resetDate, resetDuration, resetPrice, resetQuery, resetRating } from "@/search"

const form = ref(null)
const query = ref("")
const date = ref("")
const filters = ref([])
const items = ref([])
const duration = ref("Duration")
const price = ref("Price")
const showFilters = ref(false)
const suggestions = ref([])

const place = computed(() => {
  if (!query.value.length) {
    return
  }

  return find(items.value, item => item.name.toLowerCase() === query.value.toLowerCase())
})

const selectedFilters = computed(() => {
  return !!filter([query, date, duration, filters, price].map(ref => ref.value.length)).length
})

const selectedTypes = computed(() => {
  return uniq(map(filters.value, "parentNode.dataset.type"))
})

const addFilter = (item) => {
  if (item.click) {
    item.click()
  } else {
    form.value.querySelector(item).click()
  }
}

const removeFilter = (item) => {
  if (item.name === "min_rating") {
    resetRating()

    return
  }

  item.click()
}

const inspect = () => {
  query.value = form.value.q.value
  date.value = inspectDate()
  duration.value = inspectDuration()
  price.value = inspectPrice()
  filters.value = Array.from(form.value.querySelectorAll(".filter:not(.radio) input:checked"))
  suggestions.value = loadSuggestions()
}

const inspectDate = () => {
  const from = form.value.querySelectorAll(".datepicker--from")[0].value
  const to = form.value.querySelectorAll(".datepicker--to")[0].value

  if (!from && !to) {
    return ""
  }

  const text = (() => {
    const from_date = new Date(from)
    const to_date = new Date(to)

    const from_month = from && formatDate(from_date, "month")
    const to_month = to && formatDate(to_date, "month")

    if (from_month === to_month) {
      return `${from_month}`
    } else if (from_date?.getFullYear() === to_date?.getFullYear()) {
      if (from_date.getMonth() === 0 && to_date.getMonth() === 11) {
        return `${from_date.getFullYear()}`
      }

      return `${from_month.slice(0, -5)} – ${to_month}`
    } else if (from && to) {
      return `${from_month} – ${to_month}`
    } else if (from) {
      return `After ${from_month}`
    }

    return `Before ${to_month}`
  })()

  return `📅 ${text}`
}

const inspectDuration = () => {
  const duration = form.value.querySelector("#id_duration_range_label").innerHTML.replace("Duration", "").trim()

  return duration ? `⏰ ${duration}` : ""
}

const inspectPrice = () => {
  const price = form.value.querySelector("#id_price_range_label").innerHTML.replace("Price", "").trim()
  const period = form.value.rate.value.slice(0, -2)

  return price ? `🤑 ${price}/${period}` : ""
}

const loadItems = () => {
  if (!window.autocomplete) {
    setTimeout(loadItems, 100)

    return
  }

  items.value = window.autocomplete

  inspect()
}

const loadSuggestions = () => {
  if (document.forms.search.querySelectorAll(".trip").length < 10) {
    return []
  }

  const suggestions = filter(Array.from(form.value.querySelectorAll(".filter[data-suggest] input:not(:checked)")), item => {
    if (place.value && item.parentNode.dataset.type === "region") {
      return false
    }

    return !selectedTypes.value.includes(item.parentNode.dataset.type)
  })

  const groups = groupBy(suggestions, "parentNode.dataset.type")

  return filter(flatten(zip(...Object.values(groups)))).slice(0, 5)
}

const toggleFilters = () => {
  window.toggleFilters()

  showFilters.value = window.showFilters
}

onMounted(() => {
  form.value = document.forms.search

  $(document).on("change pjax:end reset submit", form.value, () => inspect())

  loadItems()

  inspect()

  if (document.location.hash === "#filters") {
    toggleFilters()
  }
})
</script>
