<template>
  <div class="alert alert-info py-3">
    <h3 class="h5">
      Invite people to review {{ community.name }} on Coworkations 🤘
    </h3>

    <p>
      Reviews help build trust with potential customers and improve your visibility in search. Share your review link to collect feedback from past participants.
    </p>

    <div class="flex flex-col md:flex-row items-center justify-between gap-2">
      <input class="form-control" :value="url" @click="copyLink">

      <div class="flex gap-2 w-full md:w-auto whitespace-nowrap">
        <share title="Share your review link" :url="url" v-slot="{ openModal }">
          <button
            class="btn btn-outline-info w-full"
            type="button"
            @click="openModal"
          >
            Share link
          </button>
        </share>

        <button
          class="btn btn-info w-full"
          type="button"
          @click="sendInvites"
        >
          Send invites
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import Share from "@/components/Share.vue"
import useClipboard from "vue-clipboard3"
import { filter, uniq } from "lodash-es"

const props = defineProps({
  community: Object,
  url: String,
})

const { toClipboard } = useClipboard()

const copyLink = () => {
  toClipboard(props.url).then(() => {
    toast.success("Link copied to clipboard")
  }, () => {
    toast.error("There was a problem copying the link to the clipboard")
  })
}

const sendInvites = () => {
  const user = window.coworkations.user

  Swal.fire({
    title: `<span>Send invites to review <span class="whitespace-nowrap">${props.community.name}</span></span>`,
    html: `<p>Enter emails to send a personal invite to, one per line 🙏</p>`,
    footer: `
      <pre class="block bg-zinc-50 border border-zinc-200 p-2 text-left text-xs rounded-lg whitespace-pre-wrap">${user.name} has invited you to review ${props.community.name} on Coworkations!

[magic link]

Safe travels!

Coworkations 🏝</pre>
`,
    input: "textarea",
    confirmButtonColor: "#17a2b8",
    confirmButtonText: "Send invites",
    preConfirm: (value) => {
      if (!value) {
        Swal.showValidationMessage("The emails field is required")

        return
      }

      const emails = uniq(filter(value.split("\n").map(email => email.trim())))

      return axios.post(`/api/communities/${props.community.slug}/reviews`, { emails })
        .then(result => result.data.emails)
        .catch(error => {
          if (error.response.data.message) {
            Swal.showValidationMessage(error.response.data.message)
          } else {
            Swal.showValidationMessage(error.response.statusText)
          }
        })
    },
    showCancelButton: true,
    showCloseButton: true,
  }).then(({ value }) => {
    if (!value) {
      return
    }

    Swal.fire({
      title: value.length === 1
        ? `Invite sent to ${value[0]} 🤘`
        : `Invites sent to ${value.length} emails 🤘`,
      text: "Ask them to check their email",
      icon: "success",
    })
  })
}
</script>
