<template>
  <slot v-if="$slots.default" v-bind="{ openModal, title }"/>
  <button
    v-else
    class="
      inline-flex gap-2 p-2 items-center justify-center text-sm sm:px-3
      sm:text-base
    "
    type="button"
    v-bind="{ title }"
    @click="openModal"
  >
    <i class="fa fa-share"/>
    <span class="font-weight-bold">Share</span>
  </button>
</template>

<script setup>
import useClipboard from "vue-clipboard3"

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  url: {
    type: String,
    required: true,
  },
})

const { toClipboard } = useClipboard()

const openModal = () => {
  window.copyLink = () => {
    toClipboard(props.url).then(() => {
      toast.success("Link copied to clipboard")
    }, () => {
      toast.error("There was a problem copying the link to the clipboard")
    })
  }

  Swal.fire({
    title: props.title,
    showConfirmButton: false,
    showCloseButton: true,
    html: `
      <div class="grid grid-cols-2 gap-3 p-1">
        <button
          class="
            inline-flex border border-slate-200 w-full p-3 items-center font-semibold text-slate-700 text-xl leading-tight rounded-lg whitespace-nowrap
            hover:bg-slate-100 hover:border-slate-200 hover:text-slate-800 hover:no-underline
            active:bg-slate-200 active:border-slate-300
          "
          onclick="copyLink()"
        >
          <i class="far fa-copy fa-fw mr-2 text-2xl md:text-3xl"></i>
          Copy Link
        </button>
        <a
          href="mailto:?subject=&body=${props.url}"
          class="
            inline-flex border border-slate-200 w-full p-3 items-center font-semibold text-slate-700 text-xl leading-tight rounded-lg whitespace-nowrap
            hover:bg-slate-100 hover:border-slate-200 hover:text-slate-800 hover:no-underline
            active:bg-slate-200 active:border-slate-300
          "
          rel="nofollow noopener"
          target="_blank"
        >
          <i class="far fa-envelope fa-fw mr-2 text-2xl md:text-3xl"></i>
          Email
        </a>
        <a
          href="sms://?&body=${props.url}"
          class="
            inline-flex border border-slate-200 w-full p-3 items-center font-semibold text-slate-700 text-xl leading-tight rounded-lg whitespace-nowrap
            hover:bg-slate-100 hover:border-slate-200 hover:text-slate-800 hover:no-underline
            active:bg-slate-200 active:border-slate-300
          "
          rel="nofollow noopener"
          target="_blank"
        >
          <i class="fa fa-comments fa-fw mr-2 text-2xl md:text-3xl text-[#007aff]"></i>
          Messages
        </a>
        <a
          href="https://web.whatsapp.com/send?text=${props.url}"
          class="
            inline-flex border border-slate-200 w-full p-3 items-center font-semibold text-slate-700 text-xl leading-tight rounded-lg whitespace-nowrap
            hover:bg-slate-100 hover:border-slate-200 hover:text-slate-800 hover:no-underline
            active:bg-slate-200 active:border-slate-300
          "
          rel="nofollow noopener"
          target="_blank"
        >
          <i class="fab fa-whatsapp-square fa-fw mr-2 text-2xl md:text-3xl text-[#25d366]"></i>
          WhatsApp
        </a>
        <a
          href="https://www.facebook.com/sharer.php?u=${props.url}"
          class="
            inline-flex border border-slate-200 w-full p-3 items-center font-semibold text-slate-700 text-xl leading-tight rounded-lg whitespace-nowrap
            hover:bg-slate-100 hover:border-slate-200 hover:text-slate-800 hover:no-underline
            active:bg-slate-200 active:border-slate-300
          "
          rel="nofollow noopener"
          target="_blank"
        >
          <i class="fab fa-facebook-square fa-fw mr-2 text-2xl md:text-3xl text-[#1877f2]"></i>
          Facebook
        </a>
        <a
          href="https://twitter.com/intent/tweet?source=tweetbutton&text=&url=${props.url}&related=coworkations"
          class="
            inline-flex border border-slate-200 w-full p-3 items-center font-semibold text-slate-700 text-xl leading-tight rounded-lg whitespace-nowrap
            hover:bg-slate-100 hover:border-slate-200 hover:text-slate-800 hover:no-underline
            active:bg-slate-200 active:border-slate-300
          "
          rel="nofollow noopener"
          target="_blank"
        >
          <i class="fab fa-twitter-square fa-fw mr-2 text-2xl md:text-3xl text-[#1da1f2]"></i>
          Twitter
        </a>
      </div>
    `,
  })
}
</script>
