<template>
  <code-embed class="!p-4" v-bind="{ src, themes }" v-slot="{ src }">
    <a v-bind="{ href, rel, target, ...$attrs }">
      <component :is="type" v-bind="{ src }" alt="" :style="[
        type === 'iframe' && 'pointer-events: none;',
        'width: 100%; height: 100%;',
      ]"/>
    </a>
  </code-embed>

  <template v-if="type === 'img'">
    <component
      v-for="theme in themes.slice(1)"
      class="hidden"
      :is="type"
      :key="theme"
      :src="`${src}?theme=${theme}`"
    />
  </template>
</template>

<script setup>
import CodeEmbed from "@/components/CodeEmbed.vue"

defineOptions({
  inheritAttrs: false,
})

const props = defineProps({
  href: {
    type: String,
    required: true,
  },
  rel: {
    type: String,
    default: "noopener",
  },
  target: {
    type: String,
    default: "_blank",
  },
  src: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: "img",
  },
})

const themes = ["light", "neutral", "dark"]
</script>
